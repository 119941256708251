import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  body, 
  html, 
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }
  
  body, 
  html {
    font-family: "OpenSansRegular" , "Arial", sans-serif;
    font-display: swap;
  }
  
  body {
    margin: 0;
  }
 
  html {
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }

  @font-face {
    font-family: 'OpenSansBold';
    src:  url('/fonts/OpenSans-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'OpenSansBoldItalic';
    src:  url('/fonts/OpenSans-BoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'OpenSansExtraBold';
    src:  url('/fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'OpenSansExtraBoldItalic';
    src:  url('/fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'OpenSansItalic';
    src:  url('/fonts/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'OpenSansLight';
    src:  url('/fonts/OpenSans-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'OpenSansLightItalic';
    src:  url('/fonts/OpenSans-LightItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'OpenSansRegular';
    src:  url('/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'OpenSansSemibold';
    src:  url('/fonts/OpenSans-Semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'OpenSansSemiboldItalic';
    src:  url('/fonts/OpenSans-SemiboldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`
export const colors = {
  // prim: "#99cc66",
  // primDark: "#698c46",
  // sec: "#9966ff",
  nav: "#89dfd2",

  prim: "#0cd892",
  primDark: "#018060",
  sec: "#369946",
  secLight: "#18B2D9",
  black: "#000000",
  text: "#1d1d1b",
}

export const size = {
  zero: "0px",
  phone: "480px",
  portrait: "600px",
  landscape: "900px",
  laptop: "1200px",
  desktop: "1600px",
  television: "1800px",
  projector: "2560px",
}

export const device = {
  zero: `(min-width: ${size.zero})`,
  phone: `(min-width: ${size.phone})`,
  portrait: `(min-width: ${size.portrait})`,
  landscape: `(min-width: ${size.landscape})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
  television: `(min-width: ${size.television})`,
  projector: `(min-width: ${size.projector})`,
}

export const mq = {
  maxWidth: bp => `@media (max-width: ${bp})`,
  minWidth: bp => `@media (min-width: ${bp})`,
  hover: hover => `@media (hover: ${hover})`,
}

export const fontFamily = fam => `font-family: "${fam}" , "Arial", sans-serif`
