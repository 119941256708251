import React, { useRef } from "react"
import styled from "styled-components"
import { fontFamily, size, mq, colors } from "../global.style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"

export const Navigation = () => {
  const flyOutRef = useRef(null)
  const menuRef = useRef(null)

  const toggleFlyout = e => {
    flyOutRef.current.classList.toggle("--open")
  }

  const toggleMenuRef = e => {
    menuRef.current.classList.toggle("--open")
  }

  return (
    <>
      <Wrapper>
        <MobileMenu ref={flyOutRef}>
          <MenuItem>
            <StyledLink
              onClick={e => {
                toggleFlyout()
              }}
              to="/formules"
              activeClassName="active"
              partiallyActive={true}
            >
              Formules
            </StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink
              onClick={e => {
                toggleFlyout()
              }}
              to="/happy-hounds"
              activeClassName="active"
              partiallyActive={true}
            >
              Happy Hounds
            </StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink
              onClick={e => {
                toggleFlyout()
              }}
              to="/gebieden"
              activeClassName="active"
              partiallyActive={true}
            >
              Gebieden
            </StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink
              onClick={e => {
                toggleFlyout()
              }}
              to="/team"
              activeClassName="active"
              partiallyActive={true}
            >
              Team
            </StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink
              onClick={e => {
                toggleFlyout()
              }}
              to="/reacties"
              activeClassName="active"
              partiallyActive={true}
            >
              Reacties
            </StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink
              onClick={e => {
                toggleFlyout()
              }}
              to="/extra"
              activeClassName="active"
              partiallyActive={true}
            >
              Extra
            </StyledLink>
          </MenuItem>
        </MobileMenu>
        <NavigationStyled>
          <Logo to="/">
            <img
              src={"/svg/identity/logo.svg"}
              alt="One Happy Hound"
              className="visual"
              loading="eager"
            />
            <img
              src={"/svg/identity/logo-full.svg"}
              alt="One Happy Hound"
              className="visualfull"
              loading="eager"
            />
            <div>
              <img
                src={"/svg/identity/text.svg"}
                alt="One Happy Hound"
                className="text"
                loading="eager"
              />
              <SmallText>Huisdierenzorg</SmallText>
            </div>
          </Logo>
          <Nav>
            <Menu>
              <MenuItem>
                <StyledLink
                  activeClassName="active"
                  partiallyActive={true}
                  to="/formules"
                >
                  Formules
                </StyledLink>
              </MenuItem>
              <MenuItem>
                <StyledLink
                  activeClassName="active"
                  partiallyActive={true}
                  to="/happy-hounds"
                >
                  Happy Hounds
                </StyledLink>
              </MenuItem>
              <MenuItem>
                <StyledLink
                  activeClassName="active"
                  partiallyActive={true}
                  to="/gebieden"
                >
                  Gebieden
                </StyledLink>
              </MenuItem>
              <MenuItem>
                <StyledLink
                  activeClassName="active"
                  partiallyActive={true}
                  to="/team"
                >
                  Team
                </StyledLink>
              </MenuItem>
              <MenuItem>
                <StyledLink
                  activeClassName="active"
                  partiallyActive={true}
                  to="/reacties"
                >
                  Reacties
                </StyledLink>
              </MenuItem>
              <MenuItem>
                <StyledLink
                  activeClassName="active"
                  partiallyActive={true}
                  to="/extra"
                >
                  Extra
                </StyledLink>
              </MenuItem>
            </Menu>
            <Socials>
              <StyledSocialLink
                href="https://www.instagram.com/1happyhound/?hl=nl"
                target="_blank"
              >
                <StyledFontAwesomeIcon icon={faInstagramSquare} />
              </StyledSocialLink>
              <StyledSocialLink
                href="https://www.facebook.com/vanguchtsteven"
                target="_blank"
              >
                <StyledFontAwesomeIcon icon={faFacebookSquare} />
              </StyledSocialLink>
              <StyledSocialLink href="mailto:info@happyhound.be">
                <NavButton src={"/svg/call-me.svg"} alt="Contacteer info@happyhound.be"></NavButton>
              </StyledSocialLink>

              <NavButtonMenu
                onClick={() => {
                  toggleFlyout()
                  toggleMenuRef()
                }}
                ref={menuRef}
              >
                <img src={"/svg/menu.svg"} alt="Menu" />
              </NavButtonMenu>
            </Socials>
          </Nav>
        </NavigationStyled>
      </Wrapper>
      <Placeholder />
    </>
  )
}

const Wrapper = styled.header`
  position: fixed;
  z-index: 20;
  width: 100%;
`

const MobileMenu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 1rem 0;
  z-index: 22;
  position: relative;
  backdrop-filter: blur(10px);
  background-color: rgba(12, 216, 190, 0.6);
  display: none;

  &.--open {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  ${mq.minWidth("750px")} {
    &.--open {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  ${mq.minWidth("1400px")} {
    &.--open {
      display: none;
    }
  }
`

const NavigationStyled = styled.div`
  position: relative;
  width: 100%;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;

  backdrop-filter: blur(15px);
  background-color: rgba(12, 216, 190, 0.2);
  width: 100%;

  ${mq.minWidth(size.phone)} {
    height: 6.25rem;
  }
`

const Logo = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1);
  text-decoration: none;

  &:hover {
    transform: translate(0.5rem, 0);
  }

  .visualfull,
  .visual {
    margin: 0 1rem;
    width: 4rem;
    height: 3.189375rem;
    height: auto;
    display: none;

    @media screen and (min-width: 540px) {
      display: block;
    }

    @media screen and (min-width: 740px) {
      width: 6.1875rem;
      height: 4.9345625rem;
    }
  }

  .visualfull {
    display: block;

    @media screen and (min-width: 540px) {
      display: none;
    }
  }

  .text {
    width: 11rem;
    height: auto;
    display: none;
    position: relative;
    left: -1px;

    @media screen and (min-width: 540px) {
      display: block;
    }

    @media screen and (min-width: 600px) {
      width: 14.625rem;
    }
  }
`

const SmallText = styled.small`
  ${fontFamily("OpenSansLight")};
  color: #000;
  text-decoration: none;
  font-size: 0.6rem;
  display: none;

  @media screen and (min-width: 540px) {
    display: block;
  }
  @media screen and (min-width: 600px) {
    font-size: 0.8rem;
  }
`

const Nav = styled.nav`
  display: flex;
`

const Menu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none;

  @media screen and (min-width: 1400px) {
    display: flex;
  }
`

const MenuItem = styled.li`
  position: relative;
  ${fontFamily("OpenSansExtraBold")};
  font-weight: bold;
  font-display: auto;
  font-size: 1.125rem;
  line-height: 2;
  color: ${colors.text};
  margin-right: 1.125rem;
`

const Socials = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`

const NavButton = styled.img`
  width: auto;
  margin-right: 0.5rem;
  height: 28px;
  display: block;

  @media screen and (min-width: 740px) {
    margin-right: 1.75rem;
  }
`

const NavButtonMenu = styled.a`
  margin-right: 0.5rem;

  @media screen and (min-width: 740px) {
    margin-right: 1.75rem;
  }

  img {
    height: 28px;
    width: auto;
    display: block;
    margin-right: 0.5rem;
  }

  @media screen and (min-width: 1400px) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  position: relative;
  color: ${colors.black};
  padding: 0.25rem 0.75rem;
  transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1);

  &:after {
    content: "";
    height: 2px;
    width: calc(100% - 1.5rem);
    bottom: 0;
    left: 0.75rem;
    display: none;
    background-color: ${colors.black};
    position: absolute;
  }

  &.active,
  &:hover {
    &:after {
      display: block;
    }
  }
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 2rem !important;
  margin-right: 0.5rem !important;

  @media screen and (min-width: 740px) {
    margin-right: 1.75rem !important;
  }
`

const StyledSocialLink = styled.a`
  text-decoration: none;
  color: ${colors.black};
  transition: opacity 0.7s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    opacity: 0.7;
  }
`

const Placeholder = styled.div`
  width: 100%;
  height: 5rem;

  ${mq.minWidth(size.phone)} {
    height: 6.25rem;
  }
`
