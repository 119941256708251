import React from "react"
import styled from "styled-components"
import { colors, fontFamily, mq, size } from "../global.style"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
export const Disclaimer = () => {
  const disc = useStaticQuery(graphql`
    query Disclaimer {
      sanityFooter {
        address
        tel
        email
        tav
      }
    }
  `)
  return (
    <DisclaimerStyled>
      <Container>
        <Text>
          <StyledLinkInline to="/">OneHappyHound</StyledLinkInline>
          <span> - </span>
          {disc.sanityFooter.tav}
          <HiddenOnMobile> - </HiddenOnMobile>
          <StyledAnchor href={`tel:${disc.sanityFooter.tel}`}>
            {disc.sanityFooter.tel}
          </StyledAnchor>
          <HiddenOnMobile> - </HiddenOnMobile>
          <StyledAnchor href={`mailto:${disc.sanityFooter.email}`}>
            {disc.sanityFooter.email}
          </StyledAnchor>
          <HiddenOnMobile> - </HiddenOnMobile>
          <StyledLink to="/algemene-voorwaarden">
            <strong>Algemene voorwaarden</strong>
          </StyledLink>
          <HiddenOnMobile> - </HiddenOnMobile>
          <StyledLink to="/privacy-policy">
            <strong>Privacy policy</strong>
          </StyledLink>
          <HiddenOnMobile> - </HiddenOnMobile>
          <StyledInlineAnchor href="https://www.robbiebardijn.be">
            Designed & Crafted By <strong>CodeTail</strong>
          </StyledInlineAnchor>
          <span> - </span>© {`${new Date().getFullYear()}`}
        </Text>
      </Container>
    </DisclaimerStyled>
  )
}

const DisclaimerStyled = styled.div`
  background-color: ${colors.text};

  ${mq.minWidth(size.laptop)} {
    padding: 1rem 0;
  }

  ${mq.minWidth(size.laptop)} {
    padding: 2.5rem 0;
  }
`

const StyledLinkInline = styled(Link)`
  color: #f2f2f2;
  text-decoration: none;
  ${fontFamily("OpenSansSemiBold")};
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
`

const StyledLink = styled(Link)`
  color: #f2f2f2;
  text-decoration: none;
  ${fontFamily("OpenSansSemiBold")};
  display: block;

  ${mq.minWidth(size.phone)} {
    display: inline-block;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Text = styled.p`
  ${fontFamily("OpenSansRegular")};
  color: #f2f2f2;
  font-size: 0.875rem;
  line-height: 1.6;

  ${mq.minWidth(size.laptop)} {
    font-size: 1rem;
  }
`

const StyledAnchor = styled.a`
  color: #f2f2f2;
  text-decoration: none;
  ${fontFamily("OpenSansSemiBold")};
  display: block;

  ${mq.minWidth(size.phone)} {
    display: inline-block;
  }

  &:hover {
    text-decoration: underline;
  }
`

const StyledInlineAnchor = styled.a`
  color: #f2f2f2;
  text-decoration: none;
  ${fontFamily("OpenSansSemiBold")};
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
`

const Container = styled.div`
  padding: 1rem;

  ${mq.minWidth(size.laptop)} {
    text-align: center;
  }
`

const HiddenOnMobile = styled.span`
  display: none;

  ${mq.minWidth(size.phone)} {
    display: inline;
  }
`
