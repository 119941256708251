import React from "react"
import { GlobalStyle } from "./global.style"
import { Navigation } from "./components/Navigation"
import { Disclaimer } from "./components/Disclaimer"

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Navigation />
      {children}
      <Disclaimer />
    </React.Fragment>
  )
}
